/* eslint-disable max-len */
export const environment = {
  production: true,
  token: 'botweb_access_token',
  cryptoPassword: '',
  cryptoKey: '8056483646328763',
  cryptoIV: '8056483646328763',
  api: 'https://botapi.aimthailand.com',
  showLoginTime: true,
  expireTimeSecond: 30
};
